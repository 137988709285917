import './App.scss';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { NotificationContainer, NotificationManager } from "react-notifications"

const PROD_URL = "https://api.stockandconquer.com";
const LOCAL_URL = "http://localhost:5000";

function SpinnerIcon() {
  return (
    <div className="spinner">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}

function ModelCard({ name, description, url, openModal, ticker, long_description, setIsNewTicker, isNewTicker }) {
  const [predictionData, setPredictionData] = useState(undefined);
  const [isLoading, setIsloading] = useState(false);

  // Function to handle button click
  const handlePredictionClick = () => {
    if (!ticker) {
      return NotificationManager.error(
        'Please submit a ticker before you can make predictions',
        '',
        2000 // This sets the timeout to 2 seconds
      );
    }
    console.log('mira')
    setIsloading(true)

    fetch(PROD_URL + url, {
      method: 'GET',
      credentials: 'include',  // Include cookies or session information
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        setIsNewTicker(false)
        console.log('Prediction data:', data);
        setPredictionData(data); // Update state with the fetched data
        setIsloading(false)
      })
      .catch(error => {
        console.error('Error fetching prediction:', error)
        setIsloading(false)
      });
  };
  return (
    <Card style={{ width: '18rem' }}>
      {isLoading ?
        <SpinnerIcon /> :
        <>
          {!isNewTicker && ticker && (
            <>
              {predictionData?.prediction === '0' && < div className="prediction"><h3>Less than +2%</h3></div>}
              {predictionData?.prediction === '1' && < div className="prediction positive"><h3>More than +2%</h3></div>}
              {(predictionData && predictionData?.prediction !== '1' && predictionData?.prediction !== '0') && < div className={`prediction ${predictionData?.prediction > 0 ? "positive" : "negative"}`}><h3>{predictionData?.prediction > 0 ? `+${predictionData?.prediction}` : predictionData?.prediction}%</h3></div>}
            </>
          )}
        </>

      }
      <Card.Body>
        <Card.Title>{name}</Card.Title>
        <Card.Text>
          {description}
        </Card.Text>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 'auto' }}>
          <Button variant="primary" onClick={handlePredictionClick}>
            Make prediction
          </Button>
          <button className="link-button" onClick={() => openModal(long_description, name)}>Model info</button>
        </div>
      </Card.Body>
    </Card >
  );
}

function App() {
  const [models, setModels] = useState([]);
  const [ticker, setTicker] = useState(null);
  const [text, setText] = useState('');
  const [showAboutModal, setShowAboutModal] = useState(false)
  const [innerHTML, setInnerHTML] = useState(null)
  const [modalTitle, setModalTitle] = useState(null)
  const [isNewTicker, setIsNewTicker] = useState(true)

  const [show, setShow] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(true);

  const closeWelcomeModal = () => setShowWelcomeModal(false);


  const closeModal = () => setShow(false);
  const openModal = (long_description, model_name) => {
    setShow(true);
    setModalTitle(model_name)
    setInnerHTML(long_description)
  }

  const closeAboutModal = () => setShowAboutModal(false)
  const openAboutModal = () => setShowAboutModal(true)

  useEffect(() => {
    // This code runs when the component mounts
    fetch(PROD_URL + '/models')
      .then(response => {
        console.log(response)
        return response.json()
      })
      .then(data => setModels(data))
      .catch(error => console.error('Error fetching data:', error));

    // Optional: Cleanup function to run when the component unmounts
    return () => {
      console.log('Cleanup if needed');
    };
  }, []);

  // Function to handle button click
  const handleSubmit = () => {
    setIsNewTicker(true)

    // Prepare the request payload
    const payload = {
      ticker: text
    };

    // Send a POST request to the backend
    fetch(PROD_URL + '/set_ticker', {
      method: 'POST',
      credentials: 'include',  // Include cookies or session information
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => {
        if (response.status !== 200) {
          setTicker(null); // Optionally clear the input field after submission
          setText('');
          NotificationManager.error(
            'There was an error fetching stock data for the ticker you have selected. Please try again or submit a different ticker.',
            '',
            2000 // This sets the timeout to 2 seconds
          );

          return 0;
        } else {
          return response.json()
        }
      })
      .then(data => {
        if (data !== 0) {
          setTicker(text.toUpperCase());
          return NotificationManager.success(data.message)
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="App">
      <NotificationContainer />
      <header>
        <h1 className="page-title">Stock & Conquer</h1>
      </header>
      <div className="about-button-wrapper">
        <Button variant="secondary" onClick={openAboutModal} style={{ marginTop: '10px' }}>
          About
      </Button>
      </div>
      <div className="ticker-form">
        <div className="ticker-selector">
          <Form.Control
            size="lg"
            type="text"
            placeholder="AAPL"
            value={text} // Bind the text state to the textarea
            onChange={(e) => setText(e.target.value)} // Update text when the textarea value changes
          />
        </div>
        <div className='submit-ticker'>
          <Button variant="primary" onClick={handleSubmit} style={{ marginTop: '10px' }}>
            Submit
      </Button>
        </div>
      </div>

      {ticker && <p className="ticker-selected">Ticker set to {ticker}</p>}


      <div className="models-wrapper">

        <div className="models-list">
          <h3 className="models-list__heading">Binary models:</h3>

          <div className="models-binary">
            {models
              .filter(model => model.binary) // Filter models where model.binary is true
              .map(model => (
                <ModelCard
                  key={model.id}
                  name={model.name}
                  description={model.brief_description}
                  long_description={model.long_description}
                  url={model.url}
                  openModal={openModal}
                  ticker={ticker}
                  setModalTitle={setModalTitle}
                  setIsNewTicker={setIsNewTicker}
                  isNewTicker={isNewTicker}
                />
              ))}
          </div>

          <h3 className="models-list__heading">Continuous models:</h3>
          <div className="models-continuous">
            {models
              .filter(model => !model.binary) // Filter models where model.binary is false
              .map(model => (
                <ModelCard
                  key={model.id}
                  name={model.name}
                  description={model.brief_description}
                  long_description={model.long_description}
                  url={model.url}
                  openModal={openModal}
                  ticker={ticker}
                  setModalTitle={setModalTitle}
                  setIsNewTicker={setIsNewTicker}
                  isNewTicker={isNewTicker}
                />
              ))}
          </div>
        </div>
      </div>

      <Modal show={showAboutModal} onHide={closeAboutModal}>
        <Modal.Header closeButton>
          <Modal.Title>About</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Stockandconquer.com: Master the Market, One Week at a Time</strong></p>
          <p>Inspired by the ancient Roman strategy of "divide and conquer", Stockandconquer.com is your ultimate tool for mastering today's financial markets.</p>

          <p><strong>The Strategy:</strong> Aiming for just a 2% gain each week, you could potentially double your money every year. Our app delivers data-driven estimations on whether a stock will rise by over 2% within the next five trading days.</p>

          <p><strong>How it works:</strong> We fetch historical data from the past 5 years for the stock you select. We enrich this data with several financial trading indicators. Then, you can train our available models and estimate whether a stock will rise by over 2% in the next week. Keep in mind that the tickers available can be found in the <a target="_blank" href="https://finance.yahoo.com/">Yahoo Finance API</a></p>

          <p><strong>The Outcome:</strong> By sticking to this strategy, you can conquer the market one victory at a time. But remember, Stockandconquer.com does not predict what will happen in the future. However, it can help you make educated estimations for what can happen.</p>

        </Modal.Body>
        <Modal.Footer>
          <p>If you want to contact the creator of Stockandconquer.com, please <a href="https://www.linkedin.com/in/asier-ugarteche-perez-552460a1" target="_blank">click here</a>.</p>

        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>    <div dangerouslySetInnerHTML={{ __html: innerHTML }} />
        </Modal.Body>
      </Modal>

      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div >
  );
}

export default App;
